import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Index from "../../../Index";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { DataService } from "../../../../config/DataService";
import API from "../../../../config/API";
import { useSelector } from "react-redux";

const IOSSwitch = Index.styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3e54a0",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#5a5959" : "#3e54a0",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function MerchantUserList() {
  const token = useSelector((state) => state.admin.adminToken);

  const [merchantUserList, setMerchantUserList] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [searchVal, setSearchVal] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const [loader, setLoader] = useState(false);

  const [count, setCount] = useState(0);
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getMerchantUserList = async () => {
    setLoader(true);
    const urlencoded = new URLSearchParams();

    urlencoded.append("pageNumber", page + 1);
    urlencoded.append("pageSize", rowsPerPage);
    await DataService(token)
      .post(API.Admin.GET_MERCHANT_USER_LIST, urlencoded)
      .then((res) => {
        if (res?.data?.status === 200) {
          console.log(res?.data?.data?.result, 60);
          setMerchantUserList(res?.data?.data?.result);
          setFilterData(res?.data?.data?.result);
          setCount(res?.data?.data?.totalCountOf_MerchantUser);
          setLoader(false);
        } else {
          setTimeout(() => setLoader(false), 2000);
        }
      })
      .catch((error) => {
        console.log(error, 62);
      });
  };

  useEffect(() => {
    getMerchantUserList();
  }, [page, rowsPerPage]);

  const handleSearch = (event) => {
    const result = merchantUserList.filter((item) => {
      setSearchVal(event?.target?.value);
      console.log(item, "event");
      return (
        item?.user.userName
          ?.toLowerCase()
          ?.toString()
          ?.includes(event?.target?.value?.toLowerCase()) ||
        item?.business?.businessName
          ?.toLowerCase()
          ?.toString()
          ?.includes(event?.target?.value?.toLowerCase()) ||
        item?.user?.mobile
          ?.toString()
          ?.toLowerCase()
          ?.includes(event?.target?.value?.toLowerCase()) ||
        item?.business?.businessEmail
          ?.toLowerCase()
          ?.toString()
          ?.includes(event?.target?.value?.toLowerCase())
      );
      // ||
      // item?.user?.isActive
      // ?.toLowerCase()
      // ?.toString()
      // ?.includes(event?.target?.value?.toLowerCase());
    });
    console.log(result, "result");
    setFilterData(result);
    setCount(result?.length);
    setPage(0);
  };
  console.log(searchVal, filterData, 123);
  const goToTokenDetails = (userData) => {
    navigate(`/admin/user-details`, { state: { userData } });
  };

  const userDisable = async (id, status) => {
    console.log(status, "status");
    setLoader(true);
    const formData = new URLSearchParams();
    formData.append("userId", id);
    if (status === true) {
      formData.append("status", false);
    } else {
      formData.append("status", true);
    }

    await DataService(token)
      .post(API.Admin.USER_ACTIVE_DEACTIVE, formData)
      .then((response) => {
        if (response.data.status == 200) {
          setTimeout(() => {
            toast.success(response.data.message);
            getMerchantUserList();
          }, 700);
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        // setTimeout(()=> setLoader(false),1000)

        console.log(error, "error123");
      });
  };

  return (
    <div style={{ position: "relative" }}>
      {loader && (
        <div>
          <Index.Box className="loader-main">
            <img src={Index.Png.loader} className="loader-img" />
          </Index.Box>
        </div>
      )}
      <div className={loader ? "loader-overlay" : ""}>
        <Index.Box className="dashboard-content bg-white add-user-container transaction-wrap">
          <Index.Box className="user-head-title">
            <Index.Typography
              className="admin-page-title user-list-page-title res-admin-page-title-set"
              component="h2"
              variant="h2"
            >
              Merchant User List
            </Index.Typography>

            <Index.Box className="preasale-search">
              <Index.Box className="user-search-main cus-search">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      value={searchVal}
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e)}
                    />

                    <img
                      src={Index.Svg.search}
                      className="search-grey-img"
                      alt="search grey img"
                    ></img>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="merchant-table-main">
                    <Index.Box className="page-table-main table-design-main merchant-user-table-main ">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                No.
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                User Name
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                User Mobile
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Business Name
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Business Email
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Created Date Time
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Status
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>

                          <Index.TableBody className="table-body">
                            {merchantUserList.length > 0 ? (
                              filterData?.map((row, index) => {
                                const currentIndex =
                                  page * rowsPerPage + index + 1;
                                return (
                                  <Index.TableRow
                                    key={index}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {currentIndex}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.user?.userName
                                        ? row?.user?.userName
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.user?.mobile
                                        ? row?.user?.mobile
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.business?.businessName
                                        ? row?.business?.businessName
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.business?.businessEmail
                                        ? row?.business?.businessEmail
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.user?.createdAt
                                        ? Index.moment(
                                            row?.user?.createdAt
                                          ).format("DD-MM-YYYY HH:mm:ss")
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {/* {row?.isActive===true? "Active":"Deactive"} */}
                                      {row?.user?.isActive === true
                                        ? "Active"
                                        : "Deactive"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="right"
                                    >
                                      {/* {row?.amount ? val.toFixed(2) : "-"} */}
                                      <Index.Box className="table-view-toggle-btn">
                                        <Index.IconButton>
                                          <Index.FormControlLabel
                                            control={
                                              <Index.IOSSwitch
                                                checked={
                                                  row?.user?.isActive === true
                                                }
                                                sx={{ m: 1 }}
                                                className="MuiSwitch-track-active"
                                                onClick={(event) => {
                                                  event.stopPropagation();
                                                  userDisable(
                                                    row?.user?._id,
                                                    row?.user?.isActive
                                                  );
                                                }}
                                              />
                                            }
                                            // onClick={(event) => {
                                            //   console.log("event")
                                            //   event.stopPropagation()
                                            //   userDisable(row?.user?._id,row?.user?.isActive);
                                            // }}
                                            className="switch-lable"
                                          />
                                        </Index.IconButton>
                                        <Index.IconButton
                                          onClick={() => goToTokenDetails(row)}
                                        >
                                          <Index.Visibility />
                                        </Index.IconButton>
                                      </Index.Box>
                                    </Index.TableCell>
                                  </Index.TableRow>
                                );
                              })
                            ) : (
                              <Index.TableRow>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  colSpan={7}
                                >
                                  No Record found
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>{" "}
                  <Index.Box className="pagination-main">
                    <Index.TablePagination
                      component="div"
                      page={page}
                      // count={filterData?.length}
                      count={count}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[15, 25, 50]}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelDisplayedRows={(page) =>
                        `Records ${page.from} to ${
                          page.to === -1 ? page.count : page.to
                        } of ${page.count}`
                      }
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </div>
    </div>
  );
}
