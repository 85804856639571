import React, { useState } from 'react'
import { Outlet } from 'react-router-dom';
import Index from '../../../../component/Index';
import Sidebar from '../../../../component/admin/defaultLayout/Sidebar';
import Header from '../../../../component/admin/defaultLayout/Header';
import { useSelector } from 'react-redux';
export default function Dashboard() {
  const token = useSelector((state)=>state.admin.adminToken)
  console.log(token,"adminToken")
  const [drawerOpen, setDrawerOpen] = useState(true)
  return (
    <div>
      <Index.Box className={drawerOpen ? "dashboard-main" : "dashboard-main active"}>
        <Index.Box className="dashboard-left-main">
          <Sidebar setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen} />
        </Index.Box>
        <Index.Box className="dashboard-right-main">
          <Header setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen} />
          <Index.Box className="dashboard-containt-main">
            <Outlet />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  )
}
