import React, { useState } from "react";
import Index from "../../Index";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { DataService } from "../../../config/DataService";
import API from "../../../config/API";
import { toast } from "react-toastify";

export default function ForgotPassword() {
  const { forgotId } = useParams();
  console.log(forgotId, "forgotId");
  const navigate = useNavigate();

  const initialValues = {
    email: "",
  };

  // validation Schema
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter valid email")
      .matches(
        /^[a-z][a-z0-9]*(?:\.[a-z]+)*@[a-z]+\.[a-z]{2,4}$/,
        "Please enter valid email"
      )
      .required("Please enter an email")
      .max(255, "Email must be at most 255 characters"),
  });
  const handleFormSubmit = async (values) => {
    console.log(values, 17);
    await DataService()
      .post(API.Admin.FORGOT_PASSWORD, values)
      .then((res) => {
        if (res?.data?.status === 200) {
          toast.success(res?.data?.message);
          navigate("/");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <>
      <Index.Box className="page-bg">
        <Index.Box className="login-main-content">
          <Index.Box className="grid-row login-row">
            <Index.Box sx={{ width: 1 }} className="grid-main login-main">
              <Index.Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                className="login-grid"
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 6",
                    lg: "span 8",
                  }}
                  className="grid-column login-column"
                >
                  <Index.Box className="login-bg-content f-direction">
                    <img
                      src={Index.Png.yesPayLogo}
                      className="bg-login-vector"
                    ></img>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 6",
                    lg: "span 4",
                  }}
                  className="grid-column login-column login-user-form  "
                >
                  <Index.Box className="admin-login-main-wrap">
                    <Index.Box className="admin-login-main">
                      <Index.Box className="auth-logo">
                        <img
                          src={Index.Png.yesPayLogo}
                          className="login-yesPay"
                          width={150}
                          height={80}
                        ></img>
                      </Index.Box>
                      <Index.Box className="admin-login-inner">
                        <Index.Box className="card-login-main">
                          <Index.Typography
                            component="h2"
                            variant="h2"
                            className="admin-wel-text"
                          >
                            Forgot Password
                          </Index.Typography>
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="admin-sign-para common-para"
                          >
                            Enter your email and we'll send you instructions to
                            reset your password
                          </Index.Typography>
                          <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleFormSubmit}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                            }) => (
                              <form onSubmit={handleSubmit}>
                                <Index.Box className="input-box">
                                  <Index.FormHelperText
                                    className="form-lable"
                                    style={{ marginTop: 36 }}
                                  >
                                    Enter your email
                                  </Index.FormHelperText>
                                  <Index.Box className="form-group">
                                    <Index.TextField
                                      fullWidth
                                      id="fullWidth"
                                      name="email"
                                      className="form-control"
                                      onBlur={handleBlur}
                                      value={values.email.trim()}
                                      // value={values.email}
                                      onChange={handleChange}
                                      helperText={touched.email && errors.email}
                                      error={Boolean(
                                        errors.email && touched.email
                                      )}
                                    />
                                  </Index.Box>
                                </Index.Box>
                                <Index.Box className="flex-all forgot-row">
                                  <Index.Typography></Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="admin-forgot-para common-para"
                                  >
                                    <Link className="signup-redirect" to="/">
                                      Back to login
                                    </Link>
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="btn-main login-btn-main">
                                  <Index.Button
                                    className="btn login-btn"
                                    type="submit"
                                    disableRipple
                                  >
                                    Submit
                                  </Index.Button>
                                </Index.Box>
                              </form>
                            )}
                          </Formik>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
