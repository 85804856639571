import React, { useState } from "react";
import Index from "../../Index";
import { Formik } from "formik";
import * as Yup from "yup";
import { DataService } from "../../../config/DataService";
import API from "../../../config/API";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function ResetPassword() {
  const forgotId = useParams();
  const navigate = useNavigate();
  console.log(forgotId.id, "forgotId");
  // password
  const [showPassword, setShowPassword] = useState(false);

  const initialValues = {
    password: "",
    confirmPassword: "",
    isDisable: false,
  };
  // validation Schema
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/,
        "Password length should be minimum 8 character and maximum 15 character including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
      ),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf(
        [Yup.ref("password"), null],
        "Confirm password must match with password"
      ),
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // confirm password
  const [showPassword1, setShowPassword1] = useState(false);
  const handleClickShowPassword1 = () => setShowPassword1((show1) => !show1);

  const handleFormSubmit = async (values, { setFieldValue }) => {
    setFieldValue("isDisable", true);

    let data = {
      forgotId: forgotId?.id,
      newPassword: values.password,
    };

    try {
      const response = await DataService().post(
        API.Admin.ADMIN_RESET_PASSWORD,
        data
      );
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        navigate("/");
      }
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    } finally {
      setFieldValue("isDisable", false);
    }
  };

  return (
    <>
      <Index.Box className="page-bg">
        <Index.Box className="login-main-content">
          <Index.Box className="grid-row login-row">
            <Index.Box sx={{ width: 1 }} className="grid-main login-main">
              <Index.Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                className="login-grid"
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 6",
                    lg: "span 8",
                  }}
                  className="grid-column login-column"
                >
                  <Index.Box className="login-bg-content f-direction">
                    <img
                      src={Index.Png.yesPayLogo}
                      className="bg-login-vector"
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 6",
                    lg: "span 4",
                  }}
                  className="grid-column login-column login-user-form  "
                >
                  <Index.Box className="">
                    <Index.Box className="admin-login-box">
                      <Index.Box className="admin-login-main">
                        <Index.Box className="auth-logo">
                          <img
                            src={Index.Png.yesPayLogo}
                            className="login-yesPay"
                            width={150}
                            height={80}
                          ></img>
                        </Index.Box>
                        <Index.Box className="admin-login-inner">
                          <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleFormSubmit}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              setFieldValue,
                            }) => (
                              <form onSubmit={handleSubmit}>
                                <Index.Box className="card-login-main">
                                  <Index.Typography
                                    component="h2"
                                    variant="h2"
                                    className="admin-wel-text"
                                  >
                                    Reset Password
                                  </Index.Typography>

                                  <Index.Box className="input-box input-box-admin">
                                    <Index.FormHelperText className="form-lable">
                                      Password{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </Index.FormHelperText>
                                    <Index.Box className="form-group pass_group_main">
                                      <Index.Box className="form-group pass_group_main">
                                        <Index.OutlinedInput
                                          className="form-control custom_form_control"
                                          id="outlined-adornment-password"
                                          inputProps={{ maxlength: 15 }}
                                          type={
                                            showPassword ? "text" : "password"
                                          }
                                          name="password"
                                          onBlur={handleBlur}
                                          value={values.password.trim()}
                                          onChange={handleChange}
                                          helperText={
                                            touched.password && errors.password
                                          }
                                          error={Boolean(
                                            errors.password && touched.password
                                          )}
                                          endAdornment={
                                            <Index.InputAdornment
                                              position="end"
                                              className="pass_position"
                                            >
                                              <Index.IconButton
                                                className="icon_end_icon"
                                                aria-label="toggle password visibility"
                                                onClick={
                                                  handleClickShowPassword
                                                }
                                                onMouseDown={
                                                  handleMouseDownPassword
                                                }
                                                edge="end"
                                              >
                                                {!showPassword ? (
                                                  <Index.VisibilityOff />
                                                ) : (
                                                  <Index.Visibility />
                                                )}
                                              </Index.IconButton>
                                            </Index.InputAdornment>
                                          }
                                          label="Password"
                                        />
                                        {touched.password &&
                                          errors.password && (
                                            <Index.FormHelperText error>
                                              {errors.password}
                                            </Index.FormHelperText>
                                          )}
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>

                                  <Index.Box className="input-box custom-form-details login-input">
                                    <Index.FormHelperText className="form-lable">
                                      Confirm Password{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </Index.FormHelperText>
                                    <Index.Box className="form-group pass_group_main">
                                      <Index.Box className="form-group pass_group_main">
                                        <Index.OutlinedInput
                                          className="form-control custom_form_control"
                                          inputProps={{ maxlength: 15 }}
                                          id="outlined-adornment-password"
                                          type={
                                            showPassword1 ? "text" : "password"
                                          }
                                          name="confirmPassword"
                                          onBlur={handleBlur}
                                          value={values.confirmPassword.trim()}
                                          onChange={handleChange}
                                          helperText={
                                            touched.confirmPassword &&
                                            errors.confirmPassword
                                          }
                                          error={Boolean(
                                            errors.confirmPassword &&
                                              touched.confirmPassword
                                          )}
                                          endAdornment={
                                            <Index.InputAdornment
                                              position="end"
                                              className="pass_position"
                                            >
                                              <Index.IconButton
                                                className="icon_end_icon"
                                                aria-label="toggle password visibility"
                                                onClick={
                                                  handleClickShowPassword1
                                                }
                                                onMouseDown={
                                                  handleMouseDownPassword
                                                }
                                                edge="end"
                                              >
                                                {!showPassword1 ? (
                                                  <Index.VisibilityOff />
                                                ) : (
                                                  <Index.Visibility />
                                                )}
                                              </Index.IconButton>
                                            </Index.InputAdornment>
                                          }
                                          label="Password"
                                        />
                                        {touched.confirmPassword &&
                                          errors.confirmPassword && (
                                            <Index.FormHelperText error>
                                              {errors.confirmPassword}
                                            </Index.FormHelperText>
                                          )}
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>

                                  <Index.Box className="btn-main login-btn-main primary-btn-main-comman">
                                    <Index.Button
                                      className="btn login-btn btn-dark-comman-main"
                                      disabled={values.isDisable}
                                      type="submit"
                                      disableRipple
                                    >
                                      Submit
                                    </Index.Button>
                                  </Index.Box>
                                </Index.Box>
                              </form>
                            )}
                          </Formik>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
