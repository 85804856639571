import axios from "axios";

// const API_ENDPOINT = "http://194.233.77.156:5050/api/";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
// const API_ENDPOINT="http://localhost:5050/api/"
// const API_ENDPOINT="https://orange-tribble-r95qx9wxppw2pp46-5050.app.github.dev/api/"
// export const imagePath = "http://194.233.77.156:5050/api/uploads/";
// export const imagePath = "http://localhost:5050/api/uploads/";
export const DataService = (token) => {
  return axios.create({
    baseURL: API_ENDPOINT,
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
      auth: token ? token : "",
    },
  });
};
