import React, { useRef, useState } from "react";
import Index from "../../../Index";
import MuiPhoneInput from "material-ui-phone-number";
import { Formik } from "formik";
import * as Yup from "yup";

const Profile = () => {
  const formikRef = useRef();
  const [url, setUrl] = useState("");

  const handleImage = () => {};

  const onDiscard = (resetForm) => {
    console.log(resetForm, 15);
    resetForm();
  };

  let initialValues = {
    adminName: "",
    email: "",
    phoneNumber: "",
  };
  // profile ValidationSchema
  const textAndSpaceOnly = (value) =>
    /^[a-zA-Z]+(\s[a-zA-Z]*){0,2}$/.test(value) || value.length === 0;

  const profileValidationSchema = Yup.object().shape({
    adminName: Yup.string()
      .required("Name is required")
      .test(
        "adminName",
        "Name allows only characters and space between",
        textAndSpaceOnly
      )
      .max(20, "Full Name allows maximum length 20"),
    email: Yup.string()
      .required("Email is required")
      .email("Email is not valid")
      .matches(
        /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9]+(\.[a-z0-9]+)*(\.[a-z]{2,4})$/,
        "Please enter valid email"
      )
      .max(255, "Email must be at most 255 characters"),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .min(8, "Mobile Number minimum length must be 8")
      .max(12, "Mobile Number maximum length must be 12"),
  });
  const handleFormSubmit = (values) => {
    console.log(values, 22);
  };
  return (
    <Index.Box className="tabpanel-main">
      <Index.Box className="add-user-data-main">
        <Index.Box className="edit-profile-flex">
          <Index.Box className="file-upload-btn-main">
            <img
              src={url ? url : Index.Png.yesPayLogo}
              className="upload-profile-img"
              alt="upload img"
            ></img>
            <Index.Button
              variant="contained"
              component="label"
              className="file-upload-btn"
            >
              <img
                src={Index.Svg.edit}
                className="upload-icon-img"
                alt="img"
              ></img>
              <input
                hidden
                accept="image/*"
                multiple
                type="file"
                onChange={(e) => handleImage(e)}
              />
            </Index.Button>
          </Index.Box>
        </Index.Box>
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Formik
            enableReinitialize
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={profileValidationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
              setFieldTouched,
              resetForm,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box add-user-input">
                      <Index.FormHelperText className="form-lable">
                        Name
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        {/* <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          // value={name}
                          name="name"
                          inputProps={{ maxlength: 15 }}
                          // onChange={(e) => handleName(e)}
                        /> */}
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          name="adminName"
                          value={values?.adminName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ maxLength: 20 }}
                          helperText={touched.adminName && errors.adminName}
                          error={Boolean(errors.adminName && touched.adminName)}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box add-user-input">
                      <Index.FormHelperText className="form-lable">
                        Email
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        {/* <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          // value={email}
                          name="email"
                          // onChange={(e) => handleEmail(e)}
                        /> */}
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          name="email"
                          value={values?.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          helperText={touched.email && errors.email}
                          error={Boolean(errors.email && touched.email)}
                        />
                      </Index.Box>
                      {/* <p className="error-text">{emailError}</p> */}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box add-user-input">
                      <Index.FormHelperText className="form-lable">
                        Phone Number
                      </Index.FormHelperText>
                      <Index.Box className="form-group country-code-main">
                        {/* <MuiPhoneInput
                          defaultCountry="in"
                          className="country-code-details country-code-add-user"
                          onChange={handleChange}
                        /> */}
                        <MuiPhoneInput
                          defaultCountry="in"
                          className="country-code-details country-code-add-user"
                          name="phoneNumber"
                          onChange={(value) =>
                            setFieldValue("phoneNumber", value)
                          }
                          onBlur={() => setFieldTouched("phoneNumber", true)}
                          value={values?.phoneNumber}
                        />
                        {touched.phoneNumber && errors.phoneNumber && (
                          <Index.FormHelperText error>
                            {errors.phoneNumber}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="user-btn-flex comman-btn-main">
                      <Index.Box className="discard-btn-main border-btn-main">
                        <Index.Button
                          className="discard-user-btn border-btn"
                          type="button"
                          onClick={() => onDiscard(resetForm)}
                          // onClick={() => formikRef.current.resetForm()}
                        >
                          Discard
                        </Index.Button>
                      </Index.Box>
                      <Index.Box className="save-btn-main ">
                        <Index.Button
                          className="save-user-btn primary-btn me-0"
                          // onClick={(e) => handleSubmit(e)}
                          type="submit"
                        >
                          <img
                            src={Index.Svg.save}
                            alt="save"
                            className="user-save-icon"
                          ></img>
                          Save
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default Profile;
