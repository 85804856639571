import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { useLocation, useNavigate } from "react-router-dom";
import { DataService } from "../../../../config/DataService";
import { useSelector } from "react-redux";
import API from "../../../../config/API";
import { toast } from "react-toastify";

const UserView = () => {
  const token = useSelector((state) => state.admin.adminToken);
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.userData;
  console.log(data, "8888");
  const [activeTokenList, setActiveTokenList] = useState([]);
  const [loader, setLoader] = useState(false);

  const [count, setCount] = useState([]);

  const getActiveTokenList = async () => {
    await DataService()
      .get(API.User.GET_ACTIVE_LIST)
      .then((res) => {
        if (res?.data?.status === 200) {
          console.log(res?.data?.data, 60);
          setActiveTokenList(res?.data?.data);

          setCount(res?.data?.data?.length);
        }
      })
      .catch((error) => {
        console.log(error, 62);
      });
  };
  const userDisable = async (id, status) => {
    try {
      setLoader(true);
      const formData = new URLSearchParams();
      formData.append("id", id);
      if (status === true) {
        formData.append("status", false);
      } else {
        formData.append("status", true);
      }

      await DataService(token)
        .post(API.Admin.TOKEN_ACTIVE_DEACTIVE, formData)
        .then((response) => {
          if (response.data.status == 200) {
            setTimeout(() => {
              toast.success(response.data.message);
              getActiveTokenList();
            }, 700);
          }
        });
      // .catch((error) => {
      //   toast.error(error?.data?.message);
      //   // setTimeout(()=> setLoader(false),1000)

      // });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error, 65);
      setTimeout(() => setLoader(false), 1000);
    }
  };

  useEffect(() => {
    // getActiveTokenList();
  }, []);
  return (
    <>
      <div style={{ position: "relative" }}>
        {loader && (
          <div>
            <Index.Box className="loader-main">
              <img src={Index.Png.loader} className="loader-img" />
            </Index.Box>
          </div>
        )}
        <div className={loader ? "loader-overlay" : ""}>
          <Index.Box className="user-details">
            <Index.Box className="admin-dashboad-row res-admin-dashboad-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dashboad-row res-admin-dashboad-row mb-0">
                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          className="display-row"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 2, sm: 2, md: 2, lg: 0 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="admin-dashboard-box">
                              <Index.Box className="admin-dashboard-box-header-main">
                                <Index.IconButton
                                  onClick={() =>
                                    navigate("/admin/merchant-user-list")
                                  }
                                  className="admin-back-icon-main"
                                >
                                  <Index.ArrowBackIcon className="admin-back-icon" />
                                </Index.IconButton>
                                <Index.Typography className="admin-page-title">
                                  Demographic info
                                </Index.Typography>
                              </Index.Box>

                              <Index.Box className="admin-dashboard-inner-box bg-white user-dashboard-inner-box basic-tabcard-main">
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 3",
                                    lg: "span 3",
                                  }}
                                  className="grid-column basic-card-detail"
                                >
                                  <Index.Typography>User Name</Index.Typography>
                                  <Index.Typography className="inner-text">
                                    {data?.user?.userName
                                      ? data?.user?.userName
                                      : "-"}
                                  </Index.Typography>
                                </Index.Box>

                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 3",
                                    lg: "span 3",
                                  }}
                                  className="grid-column basic-card-detail"
                                >
                                  <Index.Typography>Email</Index.Typography>
                                  <Index.Typography className="inner-text">
                                    {data?.user?.userEmail
                                      ? data?.user?.userEmail
                                      : "-"}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 2",
                                    lg: "span 2",
                                  }}
                                  className="grid-column basic-card-detail"
                                >
                                  <Index.Typography>
                                    Mobile No.
                                  </Index.Typography>
                                  <Index.Typography className="inner-text">
                                    {data?.user?.mobile
                                      ? data?.user?.countryCode +
                                        data?.user?.mobile
                                      : "-"}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 2",
                                    lg: "span 2",
                                  }}
                                  className="grid-column basic-card-detail"
                                >
                                  <Index.Typography> Country</Index.Typography>
                                  <Index.Typography>
                                    {data?.user?.country
                                      ? data?.user?.country
                                      : "-"}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 2",
                                    lg: "span 2",
                                  }}
                                  className="grid-column basic-card-detail"
                                >
                                  <Index.Typography>State</Index.Typography>
                                  <Index.Typography>
                                    {data?.user?.state
                                      ? data?.user?.state
                                      : "-"}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 3",
                                    lg: "span 3",
                                  }}
                                  className="grid-column basic-card-detail"
                                >
                                  <Index.Typography> City</Index.Typography>
                                  <Index.Typography>
                                    {data?.user?.city ? data?.user?.city : "-"}
                                  </Index.Typography>
                                </Index.Box>

                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 3",
                                    lg: "span 3",
                                  }}
                                  className="grid-column basic-card-detail"
                                >
                                  <Index.Typography> Pincode</Index.Typography>
                                  <Index.Typography>
                                    {data?.user?.pinCode
                                      ? data?.user?.pinCode
                                      : "-"}
                                  </Index.Typography>
                                </Index.Box>

                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 6",
                                  }}
                                  className="grid-column basic-card-detail"
                                >
                                  <Index.Typography>
                                    Permanent Address
                                  </Index.Typography>
                                  <Index.Typography>
                                    {data?.user?.userAddress
                                      ? data?.user?.userAddress
                                      : "-"}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="admin-dashboad-row res-admin-dashboad-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dashboad-row res-admin-dashboad-row mb-0">
                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          className="display-row"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 2, sm: 2, md: 2, lg: 0 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="admin-dashboard-box">
                              <Index.Typography className="admin-page-title">
                                Business info
                              </Index.Typography>

                              <Index.Box className="admin-bucinessInfo-inner-box bg-white user-dashboard-inner-box basic-tabcard-main">
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 3",
                                    lg: "span 3",
                                  }}
                                  className="grid-column basic-card-detail"
                                >
                                  <Index.Typography>
                                    Business Name
                                  </Index.Typography>
                                  <Index.Typography>
                                    {data?.business?.businessName
                                      ? data?.business?.businessName
                                      : "-"}
                                  </Index.Typography>
                                </Index.Box>

                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 3",
                                    lg: "span 3",
                                  }}
                                  className="grid-column basic-card-detail"
                                >
                                  <Index.Typography>
                                    Business Email
                                  </Index.Typography>
                                  <Index.Typography>
                                    {data?.business?.businessEmail
                                      ? data?.business?.businessEmail
                                      : "-"}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 6",
                                  }}
                                  className="grid-column basic-card-detail"
                                >
                                  <Index.Typography>
                                    {" "}
                                    Permanent Address
                                  </Index.Typography>
                                  <Index.Typography>
                                    {data?.business?.businessAddress
                                      ? data?.business?.businessAddress
                                      : "-"}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 3",
                                    lg: "span 3",
                                  }}
                                  className="grid-column basic-card-detail"
                                >
                                  <Index.Typography>
                                    Business Contact No.
                                  </Index.Typography>
                                  <Index.Typography>
                                    {data?.business?.businessContactno
                                      ? data?.business?.businessContactno
                                      : "-"}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 3",
                                    lg: "span 3",
                                  }}
                                  className="grid-column basic-card-detail"
                                >
                                  <Index.Typography>
                                    Document No.
                                  </Index.Typography>
                                  <Index.Typography>
                                    {data?.business?.registerDocumentNo
                                      ? data?.business?.registerDocumentNo
                                      : "-"}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 6",
                                  }}
                                  className="grid-column basic-card-detail"
                                >
                                  <Index.Typography>Document</Index.Typography>
                                  <Index.Typography
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      window.open(
                                        `${process.env.REACT_APP_API_BASE_IMAGE_URL}/${data?.business?.registerDocument}`
                                      );
                                    }}
                                  >
                                    {data?.business?.registerDocument
                                      ? data?.business?.registerDocument
                                      : "-"}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            {/* <Index.Box className="admin-dashboard-list-row">
              <Index.Box className="admin-dashboard-box">
                <Index.Typography className="admin-page-title">
                  Payemnt info
                </Index.Typography>
              </Index.Box>

              <Index.Box className="merchantdetails-table-main">
                <Index.Box className="page-table-main table-design-main merchant-userdetails-table-main ">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="table-container"
                  >
                    <Index.Table
                      sx={{ minWidth: 200 }}
                      aria-label="simple table"
                      className="table"
                    >
                      <Index.TableHead className="table-head">
                        <Index.TableRow className="table-row">
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            align="right"
                          >
                            Name
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            align="center"
                          >
                            Action
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>

                      <Index.TableBody className="table-body">
                        {activeTokenList.length > 0 ? (
                          activeTokenList?.map((row, index) => {
                            return (
                              <Index.TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                               
                                  {row?.name ? row?.name : "-"}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  align="right"
                                >
                                
                                  <Index.Box className="table-view-toggle-btn">
                                    <Index.IconButton>
                                      <Index.FormControlLabel
                                        control={
                                          <Index.IOSSwitch
                                            checked={row?.merchecntFlag === true}
                                            sx={{ m: 1 }}
                                            className="MuiSwitch-track-active"
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              userDisable(
                                                row?._id,
                                                row?.merchecntFlag
                                              );
                                            }}
                                          />
                                        }
                                       
                                        className="switch-lable"
                                      />
                                    </Index.IconButton>
                                  </Index.Box>
                                </Index.TableCell>
                              </Index.TableRow>
                            );
                          })
                        ) : (
                          <Index.TableRow>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="table-td"
                              colSpan={7}
                            >
                              No Record found
                            </Index.TableCell>
                          </Index.TableRow>
                        )}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
              </Index.Box>
            </Index.Box> */}
          </Index.Box>
        </div>
      </div>
    </>
  );
};

export default UserView;
