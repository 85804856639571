import save from "./images/svg/save.svg";
import edit from "./images/svg/edit.svg";
import dashboardNew from "./images/svg/dashboard-new.svg";
import dashboardCppy from "./images/svg/dashboard copy.svg";
import dashboard from "./images/svg/dashboard.svg";
import settingNew from "./images/svg/setting-new.svg";
import view from "./images/svg/view.svg"
import search from "./images/svg/search.svg"
import waitListUser from "./images/svg/waitlist-user-new.svg"

const Svg = {
  save,
  edit,
  dashboard,
  settingNew,
  view,
  search,
  waitListUser,
  dashboardCppy,
  dashboardNew
};

export default Svg;
