import { createSlice } from "@reduxjs/toolkit";
import { LoginAdmin } from "./AdminService";

export const AdminSlice = createSlice({
  name: "counter",
  initialState: {
    isAuthenticated: false,
    adminToken: "",
  },
  reducers: {
    adminLogout: (state, action) => {
      state.adminToken = "";
      state.isAuthenticated = false;
    },
    getAdminToken: (state, action) => {
      state.adminToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LoginAdmin.fulfilled, (state, action) => {
      state.isAuthenticated = true;
    });
  },
});
export const { adminLogout, getAdminToken } = AdminSlice.actions;
export default AdminSlice.reducer;
