// import React from 'react'

// const EditProfile = () => {
//   return (
//     <div>EditProfile</div>
//   )
// }

// export default EditProfile
import React, { useState } from "react";
import PropTypes from "prop-types";
// import Index from "../../../../component/Index";

import Index from "../../../Index";
import Profile from "./Profile";
import ChangePassword from "./ChangePassword";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EditProfile() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Index.Box className="dashboard-content bg-white add-user-containt">
        <Index.Typography
          className="admin-page-title"
          component="h2"
          variant="h2"
        >
          Account Settings
        </Index.Typography>

        <Index.Box className="tabs-main-box">
          <Index.Box sx={{ width: "100%" }}>
            <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Index.Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="admin-tabs-main"
              >
                <Index.Tab
                  label="Edit Profile"
                  {...a11yProps(0)}
                  className="admin-tab"
                  disableRipple
                />
                <Index.Tab
                  label="Change Password"
                  {...a11yProps(1)}
                  className="admin-tab"
                  disableRipple
                />
              </Index.Tabs>
            </Index.Box>
            <TabPanel value={value} index={0} className="admin-tabpanel">
              <Profile />
            </TabPanel>
            <TabPanel value={value} index={1} className="admin-tabpanel">
              <ChangePassword />
            </TabPanel>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
