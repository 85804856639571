import React, { useRef, useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import Index from "../../../Index";
import { Formik } from "formik";
import * as Yup from "yup";

const ChangePassword = () => {
  const formikRef = useRef();

  let initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  //   change Password ValidationSchema
  const changePasswordValidationSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .required("Old password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/,
        "Password length should be minimum 8 character and maximum 15 character including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
      ),
    newPassword: Yup.string()
      .required("New password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/,
        "Password length should be minimum 8 character and maximum 15 character including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
      ),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf(
        [Yup.ref("newPassword"), null],
        "Confirm password must match with password"
      ),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setOldShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowOldPassword = () => setOldShowPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show1) => !show1);

  const handleMouseDownPassword = (event) => event.preventDefault();

  const onDiscard = (resetForm) => {
    console.log(resetForm, 15);
    resetForm();
  };
  const handleFormSubmit = (values) => {
    console.log(values, 31);
  };

  return (
    <Index.Box className="tabpanel-main">
      <Index.Box className="change-pass-main">
        <Index.Typography
          className="common-para edit-highlight-text"
          component="p"
          variant="p"
        >
          Password
        </Index.Typography>
        <Index.Typography
          className="common-para edit-para-text"
          component="p"
          variant="p"
        >
          Enter your current & new password to reset your password
        </Index.Typography>
        <Formik
          enableReinitialize
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={changePasswordValidationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Index.Box className="input-box profile-input-box input-mb-20px">
                <Index.FormHelperText className="form-lable">
                  Old Password
                </Index.FormHelperText>
                <Index.Box className="form-group pass_group_main">
                  <Index.Box className="form-group pass_group_main">
                    <Index.OutlinedInput
                      className="form-control custom_form_control"
                      // placeholder="Old Password"
                      // value={oldPassword}
                      name="oldPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.oldPassword && errors.oldPassword}
                      error={Boolean(errors.oldPassword && touched.oldPassword)}
                      inputProps={{ maxlength: 16 }}
                      id="outlined-adornment-password"
                      type={showOldPassword ? "text" : "password"}
                      endAdornment={
                        <Index.InputAdornment
                          position="end"
                          className="pass_position"
                        >
                          <Index.IconButton
                            className="icon_end_icon"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowOldPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showOldPassword ? (
                              <Index.VisibilityOff />
                            ) : (
                              <Index.Visibility />
                            )}
                          </Index.IconButton>
                        </Index.InputAdornment>
                      }
                      label="Password"
                    />
                    {touched.oldPassword && errors.oldPassword && (
                      <Index.FormHelperText error>
                        {errors.oldPassword}
                      </Index.FormHelperText>
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="input-box profile-input-box input-mb-20px">
                <Index.FormHelperText className="form-lable">
                  New Password
                </Index.FormHelperText>
                <Index.Box className="form-group pass_group_main">
                  <Index.Box className="form-group pass_group_main">
                    <Index.OutlinedInput
                      className="form-control custom_form_control"
                      // value={password}
                      // onChange={handlePassword}
                      name="newPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.newPassword && errors.newPassword}
                      error={Boolean(errors.newPassword && touched.newPassword)}
                      inputProps={{ maxlength: 16 }}
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <Index.InputAdornment
                          position="end"
                          className="pass_position"
                        >
                          <Index.IconButton
                            className="icon_end_icon"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <Index.VisibilityOff />
                            ) : (
                              <Index.Visibility />
                            )}
                          </Index.IconButton>
                        </Index.InputAdornment>
                      }
                      label="Password"
                    />
                    {touched.newPassword && errors.newPassword && (
                      <Index.FormHelperText error>
                        {errors.newPassword}
                      </Index.FormHelperText>
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="input-box profile-input-box input-mb-20px">
                <Index.FormHelperText className="form-lable">
                  Confirm New Password
                </Index.FormHelperText>
                <Index.Box className="form-group pass_group_main">
                  <Index.Box className="form-group pass_group_main">
                    <Index.OutlinedInput
                      className="form-control custom_form_control"
                      // value={confirmPassword}
                      // onChange={handleConfirmPassword}
                      name="confirmPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      error={Boolean(
                        errors.confirmPassword && touched.confirmPassword
                      )}
                      inputProps={{ maxlength: 16 }}
                      id="outlined-adornment-password"
                      type={showPassword1 ? "text" : "password"}
                      endAdornment={
                        <Index.InputAdornment
                          position="end"
                          className="pass_position"
                        >
                          <Index.IconButton
                            className="icon_end_icon"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword1}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword1 ? (
                              <Index.VisibilityOff />
                            ) : (
                              <Index.Visibility />
                            )}
                          </Index.IconButton>
                        </Index.InputAdornment>
                      }
                      label="Password"
                    />
                    {touched.confirmPassword && errors.confirmPassword && (
                      <Index.FormHelperText error>
                        {errors.confirmPassword}
                      </Index.FormHelperText>
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="user-btn-flex comman-btn-main change-pass-flex change-pass-fl-start">
                <Index.Box className="discard-btn-main border-btn-main">
                  <Index.Button
                    className="discard-user-btn border-btn"
                    // onClick={() => onDiscard(resetForm)}
                    type="button"
                    onClick={() => onDiscard(resetForm)}
                  >
                    Discard 123
                  </Index.Button>
                </Index.Box>
                <Index.Box className="save-btn-main ">
                  <Index.Button
                    className="save-user-btn primary-btn me-0"
                    // onClick={handleSubmit}
                    type="submit"
                  >
                    <img
                      src={Index.Svg.save}
                      alt="save"
                      className="user-save-icon"
                    ></img>
                    Save
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </Formik>
      </Index.Box>
    </Index.Box>
  );
};

export default ChangePassword;
