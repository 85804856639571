import React from "react";
// import { BrowserRouter as Router ,Routess,Route } from "react-router-dom";
import { BrowserRouter, Route, Routes as Routess } from "react-router-dom";
import Login from "../container/admin/auth/Login";
import ForgotPassword from "../container/admin/auth/ForgotPassword";
import ResetPassword from "../container/admin/auth/ResetPassword";
import EditProfile from "../container/admin/pages/editProfile/EditProfile";
import ChangePassword from "../container/admin/pages/editProfile/ChangePassword";
import Sidebar from "../component/admin/defaultLayout/Sidebar";
import Dashboard from "../container/admin/pages/dashboard/Dashboard";
import TransactionList from "../container/admin/pages/Transactions/TransactionList";
import Setting from "../container/admin/pages/setting/Setting";
import AdminSetting from "../container/admin/pages/setting/Setting";
import ChainList from "../container/admin/pages/configuration/ChainList";
import ViewSelectedToken from "../container/admin/pages/configuration/ViewSelectedToken";
import MerchantUserList from "../container/admin/pages/merchantUserList/MerchantUserList";
import UserView from "../container/admin/pages/merchantUserList/UserDetails";

export default function Routes() {
  return (
    <>
      <BrowserRouter>
        <Routess>
          <Route path="/" element={<Login />} />
          {/* <Route path="/admin/login" element={<Login />}/> */}
          <Route path="/admin/forgot-password" element={<ForgotPassword />} />
          <Route path="/admin/reset-password/:id" element={<ResetPassword />} />

          <Route path="/admin/sidebar" element={<Sidebar />} />

          <Route path="admin" element={<Dashboard />}>
            <Route path="edit-profile" element={<EditProfile />} />
            <Route path="transaction-list" element={<TransactionList />} />
            <Route path="setting" element={<AdminSetting />} />
            <Route path="chainList" element={<ChainList />} />
            <Route path="chainList/:name" element={<ViewSelectedToken />} />
            <Route path="merchant-user-list" element={<MerchantUserList />} />
            <Route path="user-details" element={<UserView />} />
          </Route>
        </Routess>
      </BrowserRouter>
    </>
  );
}
