import { createAsyncThunk } from "@reduxjs/toolkit";
import { DataService } from "../../../../config/DataService";
import API from "../../../../config/API";
import { toast } from "react-toastify";

export const LoginAdmin = createAsyncThunk("admin/loginAdmin", async (data) => {
  try {
    const response = await DataService().post(API.Admin.ADMIN_LOGIN, data);
    console.log(response.data, 7);
    if (response.data.status == 200) {
      toast.success(response.data.message);
    }
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message || error.message);
  }
});
